import React, { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../redux/actions/toastActions.js";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../redux/actions/userActions.js";
import axios from "axios";

const ScanPopup = ({ camera, setIsOpen, setEventId, setScannedUser }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let videoElemRef = useRef();
  const qrScannerRef = useRef(null);
  const userDetails = useSelector((state) => state.userDetails);
  const { savedUserConfig } = userDetails;

  const getAllEventAttendees = async (uidParam, eventIdParam) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL_LEADS}/attendee/${savedUserConfig?._id}/addScannedLeads?scannedUserId=${uidParam}&eventId=${eventIdParam}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          setScannedUser({
            ...response?.data?.scannedData,
            isLeadSuccess: true,
          });
          dispatch(
            showToast({
              message: "Lead Created Successfully",
              category: "success",
            })
          );
          setIsOpen(true);
          qrScannerRef.current?.destroy();
          // navigate(`/attendee/${eventIdParam}?tab=leads&view=leads`);
        }
      })
      .catch(function (error) {
        if (
          error?.response?.data?.message === "Attendee already exists" &&
          error?.response?.status === 403
        ) {
          setScannedUser({
            ...error?.response?.data?.scannedData,
            isLeadSuccess: false,
          });
          dispatch(
            showToast({
              message: "Lead already exists",
              category: "danger",
            })
          );

          setIsOpen(true);
          qrScannerRef.current?.destroy();
        } else {
          dispatch(
            showToast({
              message: "Sorry, some error occurred",
              category: "danger",
            })
          );
        }

        qrScannerRef.current?.destroy();
        // navigate(-1);
      });
  };

  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken");
    dispatch(getUserDetails({ accessToken: accessToken }));
    // setTimeout(() => {
    //   setIsOpen(true);
    // }, 2000);
  }, [dispatch, savedUserConfig?._id]);

  useEffect(() => {
    qrScannerRef.current = new QrScanner(
      videoElemRef.current,
      (result) => {
        const uidStartIndex = result.data.indexOf("uid=") + 4;
        const uidEndIndex = result.data.indexOf("&eventId=");

        const uidParam = result.data.slice(uidStartIndex, uidEndIndex);

        const eventIdStartIndex = result.data.indexOf("eventId=") + 8;
        const eventIdParam = result.data.slice(eventIdStartIndex);

        if (uidParam && eventIdParam) {
          setEventId(eventIdParam);
          getAllEventAttendees(uidParam, eventIdParam);

          qrScannerRef.current.stop();
          // navigate(-1);
        } else {
          console.log("nothing");
        }
      },
      {
        onDecodeError: (error) => {
          console.log(error);
        },
        highlightScanRegion: true,
        highlightCodeOutline: true,
        preferredCamera: camera,
        maxScansPerSecond: 10,
      }
    );

    qrScannerRef.current.start();

    return () => {
      qrScannerRef.current.stop();
    };
  }, []);

  useEffect(() => {
    function preventBackgroundScroll(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    document.body.style.overflow = "hidden";
    document.addEventListener("scroll", preventBackgroundScroll, {
      passive: false,
    });

    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("scroll", preventBackgroundScroll);
    };
  }, []);

  const handleClose = () => {
    qrScannerRef.current?.destroy();
    navigate(-1);
  };

  const changeCamera = async (id) => {
    navigate(-1);

    setTimeout(() => {
      navigate(
        `/connections/scan?camera=${
          camera === "environment" ? "user" : "environment"
        }`
      );
    }, 500);
  };

  return (
    <>
      <div className="pt-0 md:pt-0 md:mb-0 w-full md:w-full h-[100%] relative mymd:flex">
        <video
          id="videoElem"
          ref={videoElemRef}
          className={`relative mymd:w-[calc(100vw_-_400px)] w-full h-[100vh] mymd:h-full object-fill mymd:rounded-[15px]`}
        ></video>
        <div className="fixed top-[0px] left-0 right-0 flex justify-center items-center w-full text-white mymd:w-[calc(100vw_-_400px)] font-[600] text-[25px] pt-[30px]">
          <p className="bg-gradient-to-b from-transparent via-[rgba(0,0,0,0.1)] to-transparent">
            Scan Badge
          </p>
          <div
            className="flex justify-center items-center w-[30px] h-[30px] rounded-full bg-gray-500 cursor-pointer fixed right-[22px] mymd:top-[10px] mymd:right-[15px]"
            onClick={handleClose}
          >
            <img src="/svgs/Cross.svg" alt="cross" className="w-[22px]" />
          </div>
          <div
            className=" fixed flex justify-center items-center w-[30px] h-[30px] rounded-full bg-gray-500 cursor-pointer right-[65px] mymd:left-[20px]"
            onClick={changeCamera}
          >
            <img
              src="/svgs/switch-camera.svg"
              alt="camera"
              className="w-[18px]"
              title="Switch Camera"
            />
          </div>
        </div>
      </div>
      {/* <div className="w-full flex justify-start items-center border-b-2 outline-offset-8 pb-3 relative mymd:mb-[20px]">
        <span className="text-[16px] font-[500]">Genrate Leads</span>
        <img
          src="/svgs/switch-camera.svg"
          alt="camera"
          className="w-[25px] cursor-pointer ml-3"
          onClick={changeCamera}
          title="Switch Camera"
        />

        <span
          className="absolute right-0 rounded-full bg-gray-200 cursor-pointer w-[28px] h-[28px] hover:bg-gray-300"
          onClick={handleClose}
        >
          <div className="flex justify-center items-center text-gray-800 w-[28px] h-[28px] text-[17px] font-[500] hover:font-[600]">
            x
          </div>
        </span>
      </div>

      <div className="pt-0 md:pt-0 md:mb-0 mx-auto grid place-items-center  w-full md:max-w-[1440px] md:w-full mb-14 h-[300px]">
        <video
          id="videoElem"
          ref={videoElemRef}
          className={`rounded-[15px] relative top-[10px] md:top-[10px] md:w-full md:h-full w-full border-[4px] border-[#d5d5d5] mx-auto`}
        ></video>
      </div> */}
    </>
  );
};

export default ScanPopup;
