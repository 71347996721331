import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { getUserDetails } from "../../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";

const AttendeeAbout = ({ singleEvent }) => {
  const navigate = useNavigate();
  const [tab, settab] = useState("");
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const { savedUserConfig } = userDetails;
  const now = new Date().getTime();
  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken");
    dispatch(getUserDetails({ accessToken: accessToken }));
  }, [savedUserConfig?._id]);

  return (
    <div className="w-full min-h-[90vh] bg-[#FAFAFA] md:ml-[17%] md:w-[83%] md:bg-white md:min-h-full">
      <div className="w-full h-[60px] fixed top-0 bg-white flex items-center px-[16px] border-b border-[#EDEDED] md:mt-[60px] md:hidden">
        <img
          src="/svgs/Arrowleft.svg"
          className="w-[24px] h-[24px] object-cover cursor-pointer"
          onClick={() => navigate("/events?tab=upcoming")}
        />
        <p className="ml-2 text-[22px] font-[500] overflow-hidden overflow-ellipsis whitespace-nowrap w-[95%]">
          {singleEvent?.title}
        </p>
      </div>

      <div className="mt-[60px] mx-[16px] pt-[16px] md:pt-1 md:mt-[88px] pb-[90px] md:ml-[30px]">
        <img
          src={singleEvent?.coverImage}
          alt="coverimage"
          className="h-[194px] w-full rounded-[10px] shadow md:w-[550px] md:h-[300px] "
        />
        <div className="w-full ">
          <div className="font-[500] text-[22px] mt-[8px] text-[#121212] md:mt-[12px]">
            <p className="overflow-hidden overflow-ellipsis whitespace-nowrap  w-[97%]">
              {singleEvent?.title}
            </p>
          </div>
          <div className="flex text-[12px] text-[#727374] font-normal mt-[4px] md:mt-[10px] md:text-[14px]">
            <img
              src="/svgs/calender.svg"
              alt="location"
              className="h-[16px] mr-[5px] md:h-[21px]"
            />
            <span>
              {(() => {
                try {
                  if (
                    new Date(singleEvent.startDate)
                      .toString()
                      .substring(0, 10)
                      .slice(-10) ===
                    new Date(singleEvent.endDate)
                      .toString()
                      .substring(0, 10)
                      .slice(-10)
                  ) {
                    return (
                      <>
                        {new Date(singleEvent.startDate).getDate()}{" "}
                        {new Intl.DateTimeFormat("en-US", {
                          month: "short",
                        }).format(new Date(singleEvent.startDate))}
                      </>
                    );
                  } else {
                    return (
                      <>
                        {new Date(singleEvent.startDate).getDate()}{" "}
                        {new Intl.DateTimeFormat("en-US", {
                          month: "short",
                        }).format(new Date(singleEvent.startDate))}{" "}
                        to {new Date(singleEvent.endDate).getDate()}{" "}
                        {new Intl.DateTimeFormat("en-US", {
                          month: "short",
                        }).format(new Date(singleEvent.endDate))}
                      </>
                    );
                  }
                } catch (error) {
                  console.error(error);
                  return null;
                }
              })()}
            </span>
            <span>
              &nbsp;| {moment(singleEvent?.startDate).format("LT")} |&nbsp;
            </span>
            {new Date(
              new Date(singleEvent?.endDate).toLocaleString("en-US", {
                timeZone: "Asia/Kolkata",
              })
            ).getTime() <= now ? (
              <span className="font-semibold text-[#E74C3C]">Ended</span>
            ) : new Date(
                new Date(singleEvent?.startDate).toLocaleString("en-US", {
                  timeZone: "Asia/Kolkata",
                })
              ).getTime() <= now ? (
              <span className="font-semibold text-[#2ECC71]">Ongoing</span>
            ) : (
              <span className="font-semibold text-primary">Upcoming</span>
            )}
          </div>

          <div className="flex text-[12px] text-[#727374] font-normal my-[9px] items-center w-[95%] md:text-[14px]">
            <img
              src="/svgs/Location.svg"
              alt="location"
              className="h-[18px] w-[18px] mr-[5px] ml-[-3px] md:h-[22px] md:w-[24px]"
            />
            <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">
              {singleEvent.location?.addressLine1}, {singleEvent.location?.city}
              , {singleEvent.location?.state}
            </p>
          </div>
        </div>

        <div className="text-#1C1C1E sm:text-base font-normal text-base whitespace-pre-wrap">
          {singleEvent?.shortDescription?.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line
                .split(/(https?:\/\/[^\s]+|www\.[^\s]+)/g)
                .map((word, index) =>
                  /(^|[^<])(https?:\/\/[\S]+(\b|$)|www\.[^\s]+(\b|$))(\W|$)/gi.test(
                    word
                  ) ? (
                    <a
                      key={index}
                      href={word.trim()}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary break-all"
                      style={{ wordWrap: "break-word" }}
                    >
                      {word.trim()}
                    </a>
                  ) : (
                    <span key={index}>{word}</span>
                  )
                )}
              <br />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AttendeeAbout;
