import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const EventCard = ({ ele }) => {
  const now = new Date().getTime();
  const navigate = useNavigate();

  return (
    <div
      className="cursor-pointer bg-white w-full md:w-[350px]"
      onClick={() => {
        if (
          ele?.eventRole === "attendee" ||
          ele?.eventRole === "speaker" ||
          ele?.eventRole === "exhibitor" ||
          ele?.eventRole === "delegate" ||
          ele?.eventRole === "sponsor"
        ) {
          navigate(`/attendee/${ele._id}?tab=about`);
        } else {
          navigate(`/events/${ele._id}?show=eventInfo`);
        }
      }}
    >
      <div className="relative">
        <img
          src={ele.coverImage}
          alt={ele.title}
          className="h-[184px] w-full md:h-[150px] rounded-t-[10px] md:mt-0 md:w-[350px] object-cover border-x-[1px] border-t-[1px] border-[#EDEDED]"
          style={{
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
          }}
        />
        <div className="absolute top-4 font-bold text-[20px] text-white right-[15px]">
          <div
            className={`w-full flex justify-center items-center rounded-xl h-6 text-[#fff] text-sm ${
              ele?.eventRole === "attendee"
                ? "bg-[#2D9CDB]"
                : ele?.eventRole === "speaker"
                ? "bg-[#17c53a]"
                : ele?.eventRole === "exhibitor"
                ? "bg-primary"
                : ele?.eventRole === "sponsor"
                ? "bg-primary"
                : ele?.eventRole === "delegate"
                ? "bg-orange-500"
                : "bg-[#F2994A]"
            } text-center p-2`}
          >
            <div className="capitalize">{ele?.eventRole || "organizer"}</div>
            {ele?.eventRole === "attendee" ||
            ele?.eventRole === "speaker" ||
            ele?.eventRole === "exhibitor" ||
            ele?.eventRole === "sponsor" ||
            ele?.eventRole === "delegate" ? (
              ""
            ) : (
              <img src="/svgs/grade.svg" alt="Star" className="ml-1 mb-0.5" />
            )}
          </div>
        </div>
      </div>

      <div
        className="h-[96px] w-full md:h-[110px] border-x-[1px] border-b-[1px] rounded-b-[10px] md:w-[350px] border-[#EDEDED]"
        style={{
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
        }}
      >
        <div className="font-medium md:font-[500] text-[16px] md:text-[20px] pt-[8px] text-[#1C1C1E] ml-[10px] md:w-[330px] w-[95%]">
          <p className="overflow-hidden overflow-ellipsis  whitespace-nowrap">
            {ele.title}
          </p>
        </div>
        <div className="flex text-[13px] md:text-[14px] text-[#727374] ml-[8px] font-[400] my-[8px] items-center">
          <img
            src="/svgs/calender.svg"
            alt="location"
            className="h-[17px] mr-[5px] md:h-[21px]"
          />
          <span>
            {new Date(ele.startDate).toString().substring(0, 10).slice(-10) ===
            new Date(ele.endDate).toString().substring(0, 10).slice(-10) ? (
              <>
                {new Date(ele.startDate).getDate()}{" "}
                {new Intl.DateTimeFormat("en-US", {
                  month: "short",
                }).format(new Date(ele.startDate))}
              </>
            ) : (
              <>
                {new Date(ele.startDate).getDate()}{" "}
                {new Intl.DateTimeFormat("en-US", {
                  month: "short",
                }).format(new Date(ele.startDate))}{" "}
                to {new Date(ele.endDate).getDate()}{" "}
                {new Intl.DateTimeFormat("en-US", {
                  month: "short",
                }).format(new Date(ele.endDate))}
              </>
            )}
          </span>
          <span>&nbsp;| {moment(ele?.startDate).format("LT")} |&nbsp;</span>
          {new Date(
            new Date(ele?.endDate).toLocaleString("en-US", {
              timeZone: "Asia/Kolkata",
            })
          ).getTime() <= now ? (
            <span className="font-semibold text-[#E74C3C]">Ended</span>
          ) : new Date(
              new Date(ele?.startDate).toLocaleString("en-US", {
                timeZone: "Asia/Kolkata",
              })
            ).getTime() <= now ? (
            <span className="font-semibold text-[#2ECC71]">Ongoing</span>
          ) : (
            <span className="font-semibold text-primary">Upcoming</span>
          )}
        </div>

        <div className="flex text-[13px] md:text-[14px] text-[#727374] mx-[8px] font-[400] my-[8px] items-center w-[95%]">
          <img
            src="/svgs/Location.svg"
            alt="location"
            className="h-[17px] mr-[5px] md:h-[20px]"
          />

          <p className="overflow-hidden overflow-ellipsis  whitespace-nowrap">
            {ele.location?.addressLine1}, {ele.location?.city},{" "}
            {ele.location?.state}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
