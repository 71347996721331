import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_FAIL,
  USER_EVENTS_SUCCESS,
  USER_EVENTS_REQUEST,
  USER_EVENTS_FAIL,
  USER_EVENTS_EMPTY,
} from "../constants/userConstants";

// LOGIN ACTION
export const login =
  ({
    email,
    firstName,
    lastName,
    mobile,
    organization,
    jobTitle,
    linkedinAccessToken,
  }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: USER_LOGIN_REQUEST });

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL_LEADS}/user/login`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email,
            firstName,
            lastName,
            mobile,
            organization,
            jobTitle,
            linkedinAccessToken: linkedinAccessToken
              ? linkedinAccessToken
              : null,
          }),
        }
      );

      const data = await response.json();

      if (data.message) {
        throw new Error("invalid email");
      }

      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: {
          ...data.user,
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        },
      });
      localStorage.setItem("user", data.user);
    } catch (err) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: err.message,
      });
    }
  };

// LOGOUT ACTION
export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");

  dispatch({ type: USER_LOGOUT });
};

// GET USER DETAILS;
export const getUserDetails =
  ({ accessToken }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: USER_DETAILS_REQUEST });

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL_LEADS}/user`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${accessToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }

      dispatch({
        type: USER_DETAILS_SUCCESS,
        payload: {
          ...data,
        },
      });

      // dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: USER_DETAILS_FAIL, payload: error.message });
    }
  };

//USER UPDATE ACTIONS
export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const response = await fetch("/api/users/profile", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${userInfo.token}`,
      },
      body: JSON.stringify(user),
    });

    const data = await response.json();

    if (data.message) {
      throw new Error(data.message);
    }

    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
    // i am doing this change for a bug in ...
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({ type: USER_UPDATE_FAIL, payload: error });
  }
};

//USER GET ALL USER-EVENTS
export const getUserEvents =
  ({ accessToken, tab, page }) =>
  async (dispatch) => {
    try {
      dispatch({ type: USER_EVENTS_REQUEST });

      let url = `/user/events?page=${page}`;
      if (tab) {
        url = `/user/events?filter=${tab?.toLowerCase()}&page=${page}`;
      }
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL_LEADS}${url}`,
        {
          method: "GET",
          headers: {
            Authorization: `bearer ${accessToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.message) {
        throw new Error("invalid req in getUserEvents");
      }

      dispatch({
        type: USER_EVENTS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: USER_EVENTS_FAIL,
        payload: err.message,
      });
    }
  };
